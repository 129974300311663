import { Colors, Link, Text } from 'library';
import { Address } from 'models';
import React, { useState } from 'react';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { formatAddress } from 'shared';
import BusinessLocationModal from './BusinessLocationModal';
import { Grid } from '@material-ui/core';
import { styled } from '@mui/material';
import { DailyBusinessApplicantAddress } from 'internal/applicants/ApplicantModels';

export default function BusinessLocationInfo({
    businessLocationId,
    name,
    address,
    linkStyle,
}: {
    businessLocationId: number;
    name: string;
    address: Address | DailyBusinessApplicantAddress;
    linkStyle?: React.CSSProperties;
}) {
    const [showDetails, setShowDetails] = useState(false);
    function updateShowDetails() {
        setShowDetails(!showDetails);
    }

    return (
        <>
            <StyledContainer container direction="row">
                <Link onClick={updateShowDetails} style={{ display: 'block', fontWeight: 'normal', ...linkStyle }}>
                    {name}
                </Link>
                <a href={`/adminperson/business-location/${businessLocationId}`} target="_blank" rel="noreferrer">
                    <StyledOpenInNew />
                </a>
            </StyledContainer>
            <Text variant="body2">{formatAddress(address, false)}</Text>
            <BusinessLocationModal
                businessLocationId={businessLocationId}
                open={showDetails}
                onClose={() => setShowDetails(false)}
            />
        </>
    );
}

const StyledOpenInNew = styled(OpenInNew)({
    color: Colors.darkNavy,
    height: 20,
    marginLeft: 3,
});

const StyledContainer = styled(Grid)({
    padding: 2,
});
