import { TableCell } from '@mui/material';
import Row from 'internal/shared/tables/Row';
import { BusinessJobWithAppointments, PagingResult } from 'models';
import React, { useEffect } from 'react';
import SubJobHeader from '../SubJobHeader';
import { Colors, Text } from 'library';
import SubJobChips from '../SubJobChips';
import Notes from 'internal/shared/tables/Notes';
import JobDescription from 'internal/business/JobDescription';
import ShowInMap from '../ShowInMap';
import ImpersonateButton from 'internal/impersonate/ImpersonateButton';
import ExtendTrialRun from 'internal/TrialRun/ExtendTrialRun';
import DuplicateSubJob from '../Duplicate/DuplicateSubJob';
import AddDays from '../AddDays';
import StopJob from '../StopJob';
import JobUniversalLink from 'internal/business/JobUniversalLink';
import AddProvider from '../AddProvider';
import EmailShifts from '../EmailShifts';
import Matching from '../Matching/Matching';
import { UpperDaysOfWeek, daysOfTheWeek } from 'shared/Dates';
import { TimesForDay } from '../TimesForDay';
import ToggleIsRecurring from '../ToggleIsRecurring';
import { adminLog } from 'shared';
import BusinessLocationInfo from 'internal/business-location/BusinessLocationInfo';
import PaginatedTable, { PaginatedTableProps } from 'internal/shared/tables/PaginatedAdminTable';
import { stopJob, toggleRecurring } from '../substituteApi';
import { formatISO, isAfter, nextDay, parseISO } from 'date-fns';
import RemoveListing from '../RemoveListing';

interface JobTableProps extends Omit<PaginatedTableProps<BusinessJobWithAppointments>, 'children'> {
    week: Date;
    refresh: () => void;
    showLocationInfo: boolean;
    updateJobs: (jobs: PagingResult<BusinessJobWithAppointments>) => void;
}

export default function JobTable({ data, week, refresh, updateJobs, showLocationInfo, ...tableProps }: JobTableProps) {
    function updateFlag(staffId: number, businessLocationId: number) {
        const clonedJobs = structuredClone(data.results);

        clonedJobs.forEach((job) => {
            job.slots.forEach((slot) => {
                slot.appointments.forEach((appt) => {
                    appt.pairings.forEach((pairing) => {
                        if (pairing.staff.id === staffId && pairing.staff.business_location === businessLocationId) {
                            pairing.staff.background_check_complete = true;
                        }
                    });
                });
            });
        });

        updateJobs({ ...data, results: clonedJobs });
    }

    const onToggleChange = (job: BusinessJobWithAppointments, isChecked: boolean) => {
        toggleRecurring(job.id, isChecked)
            .then(() => {
                const index = data.results.findIndex((x) => x.id === job.id);
                updateJobs({
                    ...data,
                    results: [
                        ...data.results.slice(0, index),
                        { ...job, is_recurring_business: isChecked },
                        ...data.results.slice(index + 1),
                    ],
                });
            })
            .catch(adminLog);
    };

    const onStopJob = (job: BusinessJobWithAppointments) => {
        stopJob(job.id).then(refresh).catch(adminLog);
    };

    const clearExpiredViewedJobs = () => {
        const currentTimeStamp = formatISO(new Date());
        const viewedJobs = JSON.parse(localStorage.getItem('viewedJobs') || '{}');
        const viewedJobIds = Object.keys(viewedJobs);
        let hasChanges = false;

        viewedJobIds.forEach((jobId) => {
            // eslint-disable-next-line security/detect-object-injection
            const jobFromStorage = viewedJobs[jobId];
            const jobExpiryDate = parseISO(jobFromStorage.expiresAt);

            if (isAfter(parseISO(currentTimeStamp), jobExpiryDate)) {
                // eslint-disable-next-line security/detect-object-injection
                delete viewedJobs[jobId];
                hasChanges = true;
            }
        });

        if (hasChanges) {
            localStorage.setItem('viewedJobs', JSON.stringify(viewedJobs));
        }
    };

    useEffect(clearExpiredViewedJobs, []);

    return (
        <PaginatedTable data={data} {...tableProps}>
            {data.results.map((job) => (
                <Row key={job.id} oddRowColor={Colors.lightTurq}>
                    <TableCell width="25%">
                        <SubJobHeader job={job} />
                        <SubJobChips job={job} />
                        {!!job.address.business_location && showLocationInfo && (
                            <BusinessLocationInfo
                                businessLocationId={job.address.business_location.id}
                                name={job.address.business_location.name}
                                address={job.address}
                            />
                        )}
                        <Text variant="body2">
                            <b>Program Id</b>: {job.business_location_program_id}
                        </Text>
                        <Text variant="body2">
                            Created By:{' '}
                            {job.created_by ? `${job.created_by.first_name} ${job.created_by.last_name}` : ''}
                        </Text>
                        <Text variant="body2">Status: {job.status}</Text>
                        <Notes
                            tooltip="Add/Edit notes on this job"
                            name={job.headline}
                            type="ongoing"
                            objectId={job.id}
                        />
                        <JobDescription job={job} />
                        <ShowInMap job={job} />
                        <ImpersonateButton email={job.user.email} />
                        <ExtendTrialRun job={job} week={week} />
                        <DuplicateSubJob job={job} week={week} />
                        <AddDays job={job} refreshJobs={refresh} />
                        {job.business_job_type === 'TRIAL_RUN' ? (
                            <RemoveListing job={job} refresh={refresh} />
                        ) : (
                            <StopJob job={job} onStopJob={onStopJob} />
                        )}
                        <JobUniversalLink job={job} />
                        <AddProvider job={job} refreshJobs={refresh} />
                        <EmailShifts job={job} />
                        <Matching job={job} refreshJobs={refresh} week={week} withBadge={true} />
                        <ToggleIsRecurring job={job} onChange={onToggleChange} />
                    </TableCell>
                    {Object.values(UpperDaysOfWeek).map((day) => (
                        <TableCell key={day}>
                            <TimesForDay
                                job={job}
                                date={
                                    day === UpperDaysOfWeek.Monday
                                        ? week
                                        : nextDay(week, daysOfTheWeek.indexOf(day) as Day)
                                }
                                refresh={refresh}
                                updateFlag={updateFlag}
                            />
                        </TableCell>
                    ))}
                </Row>
            ))}
        </PaginatedTable>
    );
}
