import React from 'react';
import Notes from 'internal/shared/tables/Notes';
import StaffInfo from '../../../StaffInfo';
import { Refresh, ModelTraining, Map } from '@mui/icons-material';
import ProviderExternalMessaging from 'internal/providers/ProviderExternalMessaging';
import { Grid } from '@material-ui/core';
import { ProviderOutlawModal } from 'internal/shared/UserActions';
import OutreachHeaderMessages from '../../../OutreachHeaderMessages';
import BugReportsDialog from 'internal/shared/BugReportsDialog';
import { Text, IconButton } from 'library';
import { AdminProvider } from 'models';
import { refreshInfoCollect } from 'api/AdminInfoCollectApi';
import { consoleLogInDev } from 'shared';

export function WorkerProfileHeader({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) {
    const name = `${provider?.user.first_name} ${provider?.user.last_name}`;
    const compliance_state = provider?.user.compliance_state;
    function refreshWorkerInfoCollect() {
        const infoCollectRefresh = refreshInfoCollect(compliance_state, provider);
        if (!infoCollectRefresh) {
            alert('No compatible info collect found');
        } else {
            infoCollectRefresh()
                .then((resp) => {
                    alert('Done refreshing Provider Info Collect! Status: ' + resp?.status);
                    refreshProvider();
                })
                .catch(consoleLogInDev);
        }
    }

    function onRegionMapClick() {
        window.open(`/adminperson/region-map?region=${provider.region_info?.area}&user=${provider.user.id}`, '_blank');
    }

    return (
        <Grid container style={{ margin: 10 }}>
            <Grid container style={styles.topRowWrap}>
                <Grid item direction="column" style={styles.providerNameWrap}>
                    <Text bold>
                        {name} ({provider.user.id})
                    </Text>
                    <Text variant="caption">{provider.user.email}</Text>
                </Grid>
                <Grid item direction="row" style={styles.iconGroupWrap}>
                    <Notes
                        tooltip="Add notes to this provider"
                        name={name}
                        type="admin-providers"
                        objectId={provider.id}
                        refresh={refreshProvider}
                        style={styles.removeMargin}
                    />
                    <IconButton
                        tooltip="Refresh worker details"
                        icon={Refresh}
                        onClick={refreshProvider}
                        style={styles.adjustIconMargin}
                    />
                    <IconButton
                        tooltip="Region Map"
                        icon={Map}
                        onClick={onRegionMapClick}
                        style={styles.adjustIconMargin}
                    />
                    <StaffInfo userId={provider.user.id} />
                    <ProviderExternalMessaging user={provider.user} />
                    <ProviderOutlawModal user={provider.user} />
                    <IconButton
                        tooltip="Refresh worker info collect"
                        icon={ModelTraining}
                        onClick={refreshWorkerInfoCollect}
                        style={styles.adjustIconMargin}
                    />
                    <BugReportsDialog user={provider.user} />
                </Grid>
            </Grid>
            <OutreachHeaderMessages provider={provider} />
        </Grid>
    );
}

const styles = {
    removeMargin: { marginLeft: 0 },
    iconGroupWrap: { display: 'flex', marginTop: 5, marginRight: 16 },
    providerNameWrap: { display: 'flex', marginTop: 5 },
    adjustIconMargin: { marginLeft: 8 },
    topRowWrap: { display: 'flex', alignItems: 'center', marginBottom: 5, justifyContent: 'space-between' },
};
