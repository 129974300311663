import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { AdminProvider, BusinessJobWithAppointments, BusinessJobWithUser, IPairing, LeanUser } from 'models';
import { Checkbox, OutlinedTextInput, SizeableRoundedDialog } from 'library';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { client, consoleLogInDev } from 'shared';
import LoadingButton from 'reusableComponents/loadingButton';
import JobChangeConfirmation from 'parent-portal/sitters/ProviderPreview/Components/BusinessHireProviderDialog/pages/JobChangeConfirmation/JobChangeConfirmation';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { getProviderBookings } from './Matching/matchingListApi';
import { formatDateFromDateObject } from 'shared/Dates';
import { DailyBusinessApplicantJob } from 'internal/applicants/ApplicantModels';

export default function HireProviderModal({
    closeModal,
    modalOpen,
    refreshJobs,
    job,
    initialProviderUserId,
}: {
    closeModal: () => void;
    modalOpen: boolean;
    refreshJobs?: () => void;
    job: BusinessJobWithAppointments | BusinessJobWithUser | DailyBusinessApplicantJob;
    initialProviderUserId?: number;
}) {
    const [providerUserId, setProviderUserId] = useState<number | undefined>(initialProviderUserId);
    const [provider, setProvider] = useState<AdminProvider>();
    const [preferredProviders, setPreferredProviders] = useState<LeanUser[]>([]);
    const [bookings, setBookings] = useState<IPairing[]>([]);
    const [loading, setLoading] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    function getProvider() {
        if (providerUserId) {
            setLoading(true);
            Promise.all([
                client(`api/admin-providers/${providerUserId}`),
                getProviderBookings(providerUserId, formatDateFromDateObject(new Date())),
            ])
                .then(([provider, bookings]) => {
                    setProvider(provider);
                    setBookings(bookings.results);
                })
                .finally(() => setLoading(false))
                .catch(consoleLogInDev);
        }
    }
    function clearAndClose() {
        setProvider(undefined);
        setProviderUserId(undefined);
        closeModal();
    }
    useEffect(() => {
        if (modalOpen && job.preferred_sitters.preferred_sitters?.length > 0) {
            client(`api/users/?users=${job.preferred_sitters.preferred_sitters.map((entry) => entry.sitter).join(',')}`)
                .then(setPreferredProviders)
                .catch(consoleLogInDev);
        }
    }, [modalOpen, job]);

    useEffect(() => {
        if (initialProviderUserId && modalOpen) getProvider();
    }, [initialProviderUserId, modalOpen]);

    return (
        <SizeableRoundedDialog
            closeButton
            open={modalOpen}
            onClose={clearAndClose}
            style={{ height: small ? 560 : 715, width: small ? '100%' : '80%', maxWidth: 700, paddingBottom: 10 }}
        >
            {!provider ? (
                <>
                    <PageHeader iconSource={<TadaIcon />} headerText={'Add provider to job'} subtitleText={''} />
                    {preferredProviders.map((user: LeanUser) => (
                        <Checkbox
                            style={{ marginLeft: 20 }}
                            key={user.id}
                            label={`${user.first_name} ${user.last_name} (${user.id})`}
                            checked={user.id === providerUserId}
                            onChange={(checked) => {
                                if (checked) setProviderUserId(user.id);
                            }}
                        />
                    ))}
                    <Grid style={{ padding: 20 }} item container justify="space-between" alignContent="center">
                        <OutlinedTextInput
                            value={providerUserId}
                            placeholder="Provider user id"
                            onChange={(e) => setProviderUserId(parseInt(e.target.value))}
                        />
                        <LoadingButton
                            buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                            onClick={getProvider}
                            disabled={!providerUserId}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </>
            ) : (
                <>
                    <JobChangeConfirmation
                        provider={provider}
                        job={job}
                        onPress={() => {
                            refreshJobs && refreshJobs();
                            clearAndClose();
                        }}
                        viewAdminDetails={() => setDetailsOpen(true)}
                        bookings={bookings}
                    />
                    <ProviderDetailsOutreachModal
                        providerUserId={provider.user.id}
                        open={detailsOpen}
                        onClose={() => setDetailsOpen(false)}
                    />
                </>
            )}
        </SizeableRoundedDialog>
    );
}
