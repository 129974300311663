import React, { useState } from 'react';
import { Chip, Colors, Link, Text } from 'library';
import { BusinessJobWithAppointments } from 'models';
import { Email as EmailIcon, HdrAuto } from '@mui/icons-material';
import EmailCenter from './EmailCenter';
import OutstandingMatches from './Matching/OutstandingMatches';
import { Grid } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { formatMonthDayYear } from 'shared/Dates';
import { getExpirationInfo } from 'shared/Job';

export default function SubJobHeader({ job }: { job: BusinessJobWithAppointments }) {
    const [showEmailModal, setShowEmailModal] = useState(false);
    const duplicateReason = job.is_recurring_business
        ? 'System duplicated from'
        : job.trial_run_coverage
          ? 'Trial Run Coverage for'
          : 'Created from template';
    const duplicateColor = job.is_recurring_business
        ? Colors.turquoise
        : job.trial_run_coverage
          ? Colors.terraCotta
          : Colors.darkNavy;

    const { isExpired, expiresAt, expiringSoon } = getExpirationInfo(job.request_renewed_at, job.request_posted_at);

    const getChipColor = () => {
        if (!job.business_job_type || job.business_job_type === 'PERMANENT') {
            return Colors.terraCotta;
        }

        return job.business_job_type === 'TRIAL_RUN' ? Colors.mediumTerraCotta : Colors.turquoise;
    };

    const getChipText = () => {
        if (!job.business_job_type || job.business_job_type === 'PERMANENT') {
            return 'LEGACY';
        }

        return job.business_job_type === 'TRIAL_RUN' ? 'TRIAL' : 'SUB';
    };

    const getExpiredChip = () => {
        if (job.business_job_type !== 'TRIAL_RUN') {
            return;
        }

        if (expiringSoon && expiresAt && !isExpired) {
            return (
                <Chip
                    style={{ minHeight: 20, height: 20 }}
                    textColor={Colors.white}
                    backgroundColor={Colors.terraCotta}
                    label="Expiring Soon"
                    title={expiringSoon ? formatMonthDayYear(expiresAt) : ''}
                />
            );
        }

        if (isExpired) {
            return (
                <Chip
                    style={{ minHeight: 20, height: 20 }}
                    textColor={Colors.white}
                    backgroundColor={Colors.error}
                    label="Expired"
                    title={expiresAt ? formatMonthDayYear(expiresAt) : ''}
                />
            );
        }

        return null;
    };

    return (
        <Grid>
            <Text variant="body2" textStyle={{ display: 'flex', alignItems: 'center' }}>
                {job.urgent ? (
                    <span
                        style={{
                            height: 25,
                            width: 25,
                            backgroundColor: Colors.red,
                            borderRadius: 25,
                            marginRight: 5,
                            display: 'inline-block',
                        }}
                        title="Last minute post or last minute cancellation today"
                    />
                ) : null}
                {job.user.first_name} {job.user.last_name} ({job.user.id}){' '}
                {job.auto_schedule_job && (
                    <Tooltip title="Auto Scheduled">
                        <HdrAuto style={{ color: Colors.terraCotta }} />
                    </Tooltip>
                )}
                {getExpiredChip()}
                <Chip
                    style={{ minHeight: 20, height: 20 }}
                    textColor={Colors.white}
                    backgroundColor={getChipColor()}
                    label={getChipText()}
                />
                <Tooltip title="Email Center">
                    <EmailIcon style={{ cursor: 'pointer' }} onClick={() => setShowEmailModal(true)} />
                </Tooltip>
                <OutstandingMatches jobId={job.id} outstandingMatches={job.outstanding_matches} />
            </Text>
            {job.parent_request_id ? (
                <Text
                    variant="body2"
                    bold
                    textStyle={{
                        backgroundColor: duplicateColor,
                        color: Colors.white,
                        width: '80%',
                        margin: 2,
                        borderRadius: 5,
                        textAlign: 'center',
                    }}
                >
                    {duplicateReason}: {job.parent_request_id}
                </Text>
            ) : null}
            {job.missing_micro_deposits ? (
                <Text variant="body2" textStyle={{ color: Colors.error }}>
                    MISSING MICRO DEPOSITS
                </Text>
            ) : null}
            <Text variant="body2">
                {job.business[0].name} ({job.business[0].id})
            </Text>
            <Text variant="body2">
                {job.headline} ({job.id}) - {job.pay ? `$${job.pay}` : `$${job.rate_min}-${job.rate_max}`}
            </Text>
            <Text variant="body2">
                Posted: {formatMonthDayYear(job.request_posted_at)}{' '}
                {job.request_removed_at ? `(Listed removed: ${formatMonthDayYear(job.request_removed_at)})` : null}
                <Link onClick={() => window.open(`${window.location.origin}/adminperson/job-history/${job.id}`)}>
                    View History
                </Link>
            </Text>
            {showEmailModal ? (
                <EmailCenter showEmailModal={showEmailModal} setShowEmailModal={setShowEmailModal} job={job} />
            ) : null}
        </Grid>
    );
}
