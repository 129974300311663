import { useEffect, useCallback, useState } from 'react';
import makeRequest from '../Request';

import type { Request } from '../Request';

export type ExtendedOptions = {
    stringify?: boolean;
    sendImmediately?: boolean;
    abortPrevious?: boolean;
};

export interface FetchOptions extends Request {
    url: string;
    options?: ExtendedOptions;
}

// prettier-ignore
export const useClient = <T, >({ url, body, options, ...customConfig }: FetchOptions) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<T>();
    const [finished, setFinished] = useState<boolean>(false);
    const [status, setStatus] = useState<number>();
    const [refreshKey, setRefreshKey] = useState(0);
    const [controller, setController] = useState<AbortController>();

    const { stringify = true, sendImmediately = true, abortPrevious = true } = options ?? {};

    const send = useCallback(
        async (requestBody?: Request) => {
            if (controller && abortPrevious) controller.abort();

            const abortController = new AbortController();
            const { signal } = abortController;
            setController(abortController);
            setLoading(true);

            makeRequest(url, { ...(requestBody ?? body), ...{ signal, ...customConfig } }, stringify)
                .then(async (response: Response) => {
                    if (!signal.aborted) {
                        if (response.ok) {
                            if (response.headers.get('content-type')?.includes('application/json')) {
                                const data = await response.json();
                                setData(data);
                            } else if (response.headers.get('content-type')?.includes('text/html')) {
                                const data = await response.text();
                                setData(data as unknown as T);
                            } else if (response.headers.get('content-type')?.includes('application/pdf')) {
                                const data = await response.blob();
                                setData(data as unknown as T);
                            } else if (response.headers.get('content-type')?.includes('image')) {
                                const data = await response.blob();
                                setData(data as unknown as T);
                            } else if (response.headers.get('content-length') === '0') {
                                setData(undefined);
                            } else {
                                throw new Error('Unsupported content type');
                            }
                            setError(undefined);
                        } else {
                            const data = await response.json();

                            setError(response.statusText ? new Error(response.statusText) : new Error('Unknown error'));
                            setData(data);
                        }
                        setStatus(response.status);
                    }
                })
                .catch((error: Response) => {
                    if (!signal.aborted) {
                        setStatus(error.status);
                        setError(error.statusText ? new Error(error.statusText) : new Error('Unknown error'));
                    }
                })
                .finally(() => {
                    if (!signal.aborted) {
                        setFinished(true);
                        setLoading(false);
                    }
                    setController(undefined);
                });
        },
        [body, stringify, url],
    );

    useEffect(() => {
        if (sendImmediately) {
            send();
        }
    }, [sendImmediately, send, refreshKey]);

    const refresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    return { data, error, loading, finished, status, send, refresh };
};
